// import store
import store from "../store/index";


// POST api/v1/campaigntype/create
const campaignTypeCreate = async (name: string, description: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaigntype/create`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.key}`,
            },
            body: JSON.stringify({
                name: name,
                description: description
            })
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCampaignTypesCache", {
                campaignTypes: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCampaignTypesCache", {
                campaignTypes: []
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCampaignTypesCache", {
            campaignTypes: []
        });
    }
};
// GET api/v1/campaigntype/read/:campaignTypeId
const campaignTypeRead = async (campaignTypeId: number) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaigntype/read/${campaignTypeId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaign: jsonResponse.data
            });
            // return
            return jsonResponse.data;
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaign: null
            });
            // return
            return false;
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCampaignCache", {
            campaign: null
        });
        // return 
        return false;
    }
};
// GET api/v1/campaigntype/readall
const campaignTypeReadAll = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaigntype/readall`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCampaignTypeCache", {
                campaignTypeList: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCampaignTypeCache", {
                campaignTypeList: []
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCampaignTypeCache", {
            campaignTypeList: []
        });
    }
};
// PATCH api/v1/campaigntype/update/:campaignTypeId
const campaignTypeUpdate = async (campaignTypeId: string, name: string, description: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaigntype/update/${campaignTypeId}`, {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.key}`,
            },
            body: JSON.stringify({
                name: name,
                description: description
            })
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};

// export
export {
    campaignTypeRead,
    campaignTypeReadAll,
    campaignTypeCreate,
    campaignTypeUpdate
};