// import store
import store from "../store/index";

// POST api/v1/category/create
const categoryCreate = async (name: string, description: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/category/create`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.key}`,
            },
            body: JSON.stringify({
                name: name,
                description: description
            })
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// GET api/v1/category/read/:categoryId
const categoryRead = async (categoryId: number) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/category/read/${categoryId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            }
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return jsonResponse.data;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// GET api/v1/category/readall
const categoryReadAll = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/category/readall`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCategoryCache", {
                categoryList: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCategoryCache", {
                categoryList: []
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCategoryCache", {
            categoryList: []
        });
    }
};
// PATHC api/v1/category/update
const categoryUpdate = async (categoryId: number, name: string, description: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/category/update/${categoryId}`, {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.key}`,
            },
            body: JSON.stringify({
                name: name,
                description: description
            })
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};

// export
export {
    categoryCreate,
    categoryRead,
    categoryReadAll,
    categoryUpdate
};