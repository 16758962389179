
import { Component, Vue } from 'vue-property-decorator';

// import components
import HelpBasic from '../components/help/HelpBasic.vue';

@Component({
    components: {
        HelpBasic,
    },
})
export default class HelpPage extends Vue {};
